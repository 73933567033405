import { IconName } from "@fortawesome/fontawesome-svg-core";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { Text } from "./Text";
import { Button } from "./Button";

type AllowedIconName = Extract<
  IconName,
  "clock" | "comment" | "trash" | "exclamation-circle"
>;

type ConfirmationProps = {
  /** Whether the dialog should be open. */
  open: boolean;
  /**
   * A callback that will be dispatched when the confirmation interaction is complete.
   *
   * The `status` param will be:
   *
   * - `"accepted"` – if the user clicked the `accept` button
   * - `"rejected"` – if the user clicked the `reject` button
   * - `"dismissed"` – if the user dismissed the dialog by hitting `Esc` or clicking in an empty area
   */
  onResult: (status: "accepted" | "rejected" | "dismissed") => void;

  /** The name for the font-awesome icon. */
  iconName: AllowedIconName;
  body: string;
  acceptButtonText: string;
  rejectButtonText: string;
  /** Whether accepting this confirmation will result in a destructive action.
   *
   * Setting this prop to `true` will only configure the presentation of this component, it will not ensure a destructive action is taken.
   */
  destructive?: boolean;
};

const icons: Record<AllowedIconName, ReturnType<typeof icon>> = {
  clock: icon({ name: "clock", style: "duotone" }),
  comment: icon({ name: "comment", style: "duotone" }),
  trash: icon({ name: "trash", style: "duotone" }),
  "exclamation-circle": icon({ name: "exclamation-circle", style: "duotone" }),
};

/**
 * A utility component for displaying a confirmation dialog.
 */
export const Confirmation = ({
  body,
  iconName,
  acceptButtonText,
  rejectButtonText,
  open,
  onResult,
  destructive,
}: ConfirmationProps) => {
  return (
    <Dialog
      open={open}
      className="relative z-[100]"
      onClose={() => onResult("dismissed")}
    >
      <DialogBackdrop
        className="fixed inset-0 bg-neutral-800/70 duration-300 ease-out data-[closed]:opacity-0"
        transition
      />
      <DialogPanel
        className="fixed inset-0 z-10 flex h-full w-full items-center justify-center duration-300 ease-out data-[closed]:translate-y-4 data-[closed]:scale-95 data-[closed]:opacity-0"
        transition
      >
        <div
          className="flex w-[240px] flex-col rounded-3xl bg-white p-4 text-neutral-600 dark:bg-neutral-700"
          data-testid="Meso:confirmation"
        >
          <div className="flex flex-col items-center justify-center py-4 text-center">
            <div className="mb-2 flex size-12 items-center justify-center rounded-full bg-neutral-200 dark:bg-neutral-800 dark:text-white">
              <FontAwesomeIcon icon={icons[iconName]} size="xl" />
            </div>
            <Text className="font-medium">{body}</Text>
          </div>
          <div className="flex w-full flex-col gap-2 text-sm">
            <Button
              static
              onClick={() => onResult("accepted")}
              className={destructive ? "bg-orange-600" : ""}
            >
              {acceptButtonText}
            </Button>
            <Button static primary={false} onClick={() => onResult("rejected")}>
              {rejectButtonText}
            </Button>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  );
};
