import {
  AcceptTermsMutation,
  AcceptTermsMutationVariables,
  ActivateUserMutation,
  ActivateUserMutationVariables,
  AddPaymentCardMutation,
  AddPaymentCardMutationVariables,
  AddPhoneNumberMutation,
  AddPhoneNumberMutationVariables,
  AddTaxpayerIdMutation,
  AddTaxpayerIdMutationVariables,
  ResolveAddWalletMutation,
  ResolveAddWalletMutationVariables,
  CreateAccountMutationVariables,
  FullProfileStatusFragment,
  LoginWithEmailAndPasswordMutationVariables,
  MutationPerformWalletLoginArgs,
  ResolveNewSessionMutation,
  ResolveNewSessionMutationVariables,
  RiskSessionQuery,
  Sdk,
  Send2FaCodeMutationVariables,
  SetThemeMutationVariables,
  StartOnboardingMutationVariables,
  SubmitOnboardingInfoMutation,
  SubmitOnboardingInfoMutationVariables,
  UserActivationStatusQuery,
  UserActivationStatusQueryVariables,
  Verify2FaMutationVariables,
  VerifyPhoneNumberMutation,
  VerifyPhoneNumberMutationVariables,
  MutationCompletePasskeyLoginArgs,
  LoginFragment,
  PasskeyCeremony,
  IdentityDocumentUrlQuery,
  ResolveLookupUserMutation,
  AddCitizenshipMutationVariables,
  AddCitizenshipMutation,
} from "./generated/sdk";
import { Result, err, ok } from "neverthrow";
import { Sentry } from "./utils/telemetry";
import { ClientError } from "graphql-request";
import { ErrorMessages } from "@tigris/mesokit";
import { isAuthFactorError } from "@tigris/common";
import { UrlString } from "./types";

/* Wallet specific login response, can either be LoginFragment if successful, or
 * prompting for email/password in case of login_failed server response,
 * signaling address was unparseable */
export type LoginWithWalletSuccess =
  | LoginFragment
  | { loginWithEmailAndPassword: true };

export type ResolveUserResult = Result<
  Extract<ResolveLookupUserMutation["user"], { __typename: "User" }>,
  string
>;

export type ResolveActivateUserResult = Result<
  Extract<
    ActivateUserMutation["activateUser"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveAcceptTermsResult = Result<
  Extract<AcceptTermsMutation["acceptTerms"], { __typename: "ProfileStatus" }>,
  string
>;

export type ResolveAddCitizenshipResult = Result<
  Extract<
    AddCitizenshipMutation["addCitizenship"],
    { __typename: "ProfileStatus" }
  >,
  string
>;

export type ResolveAddPaymentCardResult = Result<
  {
    addPaymentCard: Extract<
      AddPaymentCardMutation["addPaymentCard"],
      { __typename: "ProfileStatus" }
    >;
    user: Extract<AddPaymentCardMutation["user"], { __typename: "User" }>;
  },
  string
>;

export type ResolveSubmitOnboardingInfoResult = Result<
  {
    addBasicInfo: Extract<
      SubmitOnboardingInfoMutation["addBasicInfo"],
      { __typename: "ProfileStatus" }
    >;
    addResidentialAddress: Extract<
      SubmitOnboardingInfoMutation["addResidentialAddress"],
      { __typename: "ProfileStatus" }
    >;
    addTaxpayerIdLast4: Extract<
      SubmitOnboardingInfoMutation["addTaxpayerIdLast4"],
      { __typename: "ProfileStatus" }
    >;
  },
  string
>;

export type ResolveVerifyPhoneNumberResult = Result<
  Extract<
    VerifyPhoneNumberMutation["verifyPhoneNumber"],
    { __typename: "ProfileStatus" }
  >,
  string
>;

export type ResolveVerify2FAResult = Result<true, string>;

export type ResolveAddPhoneNumberResult = Result<
  Extract<
    AddPhoneNumberMutation["addPhoneNumber"],
    { __typename: "ProfileStatus" }
  >,
  string
>;

export type ResolveAddTaxpayerIdResult = Result<
  Extract<
    AddTaxpayerIdMutation["addTaxpayerIdFull"],
    { __typename: "ProfileStatus" }
  >,
  string
>;

export type ResolveUserActivationStatusResult = Result<
  Extract<
    UserActivationStatusQuery["userActivation"],
    { __typename: "UserActivation" }
  >,
  string
>;

export type ResolveRiskSessionResult = Result<
  Extract<RiskSessionQuery["riskSession"], { __typename: "RiskSession" }>,
  string
>;

export type ResolveCreateAccountResult = Result<
  FullProfileStatusFragment,
  string
>;

export type ResolveStartOnboardingResult = Result<
  FullProfileStatusFragment | { loginWithEmailAndPassword: true },
  string
>;

export type ResolveSend2FACodeResult = Result<true, string>;

export type ResolveIdentityDocumentUrlResult = Result<
  Extract<
    IdentityDocumentUrlQuery["identityDocumentUrl"],
    { __typename: "IdentityDocumentUrl" }
  >,
  string
>;

/**
 * A fallback to capture exceptions when calling our API. This is not intended for handling known errors such as validation or errors-as-data results.
 */
const reportApiError = (error: unknown, operation: string) => {
  Sentry.captureException(
    error instanceof ClientError ? error.message : error,
    { tags: { operation } },
  );
};

export const api = (sdk: Sdk) => {
  return {
    async resolveSend2FACode({
      input,
    }: Send2FaCodeMutationVariables): Promise<ResolveSend2FACodeResult> {
      const OPERATION_NAME = "Send2FACode";
      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { send2FACode },
        } = result;

        if (
          !result ||
          send2FACode?.__typename === "Errors" ||
          !send2FACode?.success
        ) {
          return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
        } else {
          return ok(true);
        }
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR);
    },

    async resolveUser(): Promise<ResolveUserResult> {
      const OPERATION_NAME = "ResolveLookupUser";
      const ERROR_MESSAGE = ErrorMessages.common.LOOKUP_USER_API_ERROR;
      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { user },
        } = result;

        if (!user || user.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(user);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }
      return err(ERROR_MESSAGE);
    },

    async resolveCreateAccount({
      input,
    }: CreateAccountMutationVariables): Promise<ResolveCreateAccountResult> {
      const OPERATION_NAME = "CreateAccount";
      const ERROR_MESSAGE = ErrorMessages.createAccount.GENERIC_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { createAccount },
        } = result;

        if (
          !createAccount ||
          createAccount.__typename === "Errors" ||
          createAccount.account === false
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(createAccount);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }
      return err(ERROR_MESSAGE);
    },

    async resolveBeginPasskeyLogin(): Promise<Result<PasskeyCeremony, string>> {
      const OPERATION_NAME = "BeginPasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.UNABLE_TO_BEGIN_PASSKEY_LOGIN;

      try {
        const result = await sdk.BeginPasskeyLogin();
        const {
          data: { beginPasskeyLogin },
        } = result;

        if (!beginPasskeyLogin || beginPasskeyLogin.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(beginPasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveCompletePasskeyLogin({
      input,
    }: MutationCompletePasskeyLoginArgs): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "CompletePasskeyLogin";
      const ERROR_MESSAGE = ErrorMessages.passkey.PASSKEY_LOGIN_FAILED;

      try {
        const result = await sdk.CompletePasskeyLogin({ input });
        const {
          data: { completePasskeyLogin },
        } = result;

        if (
          !completePasskeyLogin ||
          completePasskeyLogin.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(completePasskeyLogin);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithWallet({
      input,
    }: MutationPerformWalletLoginArgs): Promise<
      Result<LoginWithWalletSuccess, string>
    > {
      const OPERATION_NAME = "LoginWithWallet";
      const ERROR_MESSAGE = ErrorMessages.landingSheet.LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithWallet },
        } = result;

        if (!loginWithWallet || loginWithWallet.__typename === "Errors") {
          const errorCode = loginWithWallet?.errors[0].code;

          if (errorCode === "login_failed") {
            return ok({ loginWithEmailAndPassword: true });
          } else if (errorCode === "invalid_signature") {
            return err(ErrorMessages.landingSheet.WALLET_SIGNING_GENERIC_ERROR);
          }

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(loginWithWallet);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveLoginWithEmailAndPassword({
      input,
    }: LoginWithEmailAndPasswordMutationVariables): Promise<
      Result<LoginFragment, string>
    > {
      const OPERATION_NAME = "LoginWithEmailAndPassword";
      const ERROR_MESSAGE =
        ErrorMessages.landingSheet.EMAIL_PASSWORD_LOGIN_FAILED;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { loginWithEmailAndPassword },
        } = result;

        if (
          !loginWithEmailAndPassword ||
          loginWithEmailAndPassword.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(loginWithEmailAndPassword);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveStartOnboarding({
      input,
    }: StartOnboardingMutationVariables): Promise<ResolveStartOnboardingResult> {
      const OPERATION_NAME = "StartOnboarding";
      const ERROR_MESSAGE =
        ErrorMessages.startOnboarding.UNABLE_TO_START_ONBOARDING;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { startOnboarding },
        } = result;

        if (!startOnboarding || startOnboarding.__typename === "Errors") {
          const errorCode = startOnboarding?.errors[0].code;
          if (errorCode === "duplicate_wallet") {
            return ok({ loginWithEmailAndPassword: true });
          }

          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(startOnboarding);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddWallet({
      input,
    }: ResolveAddWalletMutationVariables): Promise<
      Result<
        {
          user: Extract<
            ResolveAddWalletMutation["user"],
            { __typename: "User" }
          >;
        },
        string
      >
    > {
      const OPERATION_NAME = "ResolveAddWallet";
      const ERROR_MESSAGE = ErrorMessages.addWallet.UNABLE_TO_ADD_WALLET;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addWallet, user },
        } = result;

        if (
          !addWallet ||
          addWallet.__typename === "Errors" ||
          !user ||
          user.__typename === "Errors"
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok({ user });
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveActivateUser({
      input,
    }: ActivateUserMutationVariables): Promise<ResolveActivateUserResult> {
      const OPERATION_NAME = "ActivateUser";
      const ERROR_MESSAGE = "Something went wrong. Please try again.";

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { activateUser },
        } = result;

        if (!activateUser || activateUser.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok(activateUser);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddPaymentCard({
      input,
    }: AddPaymentCardMutationVariables): Promise<ResolveAddPaymentCardResult> {
      const OPERATION_NAME = "AddPaymentCard";
      const ERROR_MESSAGE = ErrorMessages.addPaymentCard.GENERIC_ERROR;

      /**
       * The error `codes` that may come back on a failed `addPaymentCard` mutation.
       */
      const apiErrorCodes: { [key: string]: string } = {
        invalid_card_information:
          ErrorMessages.addPaymentCard.ADD_PAYMENT_CARD_API_ERROR,
        unsupported_issuing_country:
          ErrorMessages.addPaymentCard.UNSUPPORTED_ISSUING_COUNTRY_API_ERROR,
        unsupported_card_type:
          ErrorMessages.addPaymentCard.DEBIT_CARDS_ONLY_ERROR,
        user_blocked: ErrorMessages.addPaymentCard.USER_BLOCKED_ERROR,
      };

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addPaymentCard, user },
        } = result;

        if (!addPaymentCard || !user || user.__typename === "Errors") {
          const errorMessage =
            ErrorMessages.addPaymentCard.ADD_PAYMENT_CARD_API_ERROR;

          return err(errorMessage);
        }

        if (addPaymentCard && addPaymentCard.__typename === "Errors") {
          const [apiError] = addPaymentCard.errors;
          const mappedErrorMessage = apiErrorCodes[apiError.code];

          const errorMessage =
            mappedErrorMessage || ErrorMessages.addPaymentCard.GENERIC_ERROR;

          return err(errorMessage);
        }

        if (user.__typename === "User") {
          return ok({
            addPaymentCard,
            user,
          });
        }

        return err(ERROR_MESSAGE);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAcceptTerms({
      input,
    }: AcceptTermsMutationVariables): Promise<ResolveAcceptTermsResult> {
      const OPERATION_NAME = "AcceptTerms";
      const ERROR_MESSAGE = ErrorMessages.agreements.ACCEPT_TERMS_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { acceptTerms },
          errors,
        } = result;

        if (errors) {
          if (isAuthFactorError(errors)) {
            // Don't report auth factor errors to sentry
            return err(ERROR_MESSAGE);
          }
        }

        if (
          !acceptTerms ||
          acceptTerms.__typename === "Errors" ||
          !acceptTerms.acceptedTerms
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(acceptTerms);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddCitizenship({
      input,
    }: AddCitizenshipMutationVariables): Promise<ResolveAddCitizenshipResult> {
      const OPERATION_NAME = "AddCitizenship";
      // For now, keeping the same error since, to a user, accepting terms and adding citizenship is a single setp.
      const ERROR_MESSAGE = ErrorMessages.agreements.ACCEPT_TERMS_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addCitizenship },
        } = result;

        if (
          !addCitizenship ||
          addCitizenship.__typename === "Errors" ||
          !addCitizenship.acceptedTerms
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(addCitizenship);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveSubmitOnboardingInfo(
      variables: SubmitOnboardingInfoMutationVariables,
    ): Promise<ResolveSubmitOnboardingInfoResult> {
      const OPERATION_NAME = "SubmitOnboardingInfo";
      const ERROR_MESSAGE = ErrorMessages.basicInfoEntry.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME](variables);
        const {
          data: { addBasicInfo, addResidentialAddress, addTaxpayerIdLast4 },
        } = result;

        if (addBasicInfo && addBasicInfo.__typename === "Errors") {
          const duplicateError = addBasicInfo.errors.find(
            (e) => e.code === "duplicate_email",
          );
          return err(
            duplicateError
              ? ErrorMessages.basicInfoEntry.DUPLICATE_EMAIL_ERROR
              : ErrorMessages.basicInfoEntry.GENERIC_API_ERROR,
          );
        } else if (
          !addBasicInfo ||
          !addResidentialAddress ||
          addResidentialAddress.__typename === "Errors" ||
          !addTaxpayerIdLast4 ||
          addTaxpayerIdLast4.__typename === "Errors"
        ) {
          return err(ErrorMessages.basicInfoEntry.GENERIC_API_ERROR);
        }

        return ok({ addBasicInfo, addResidentialAddress, addTaxpayerIdLast4 });
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveVerifyPhoneNumber({
      input,
    }: VerifyPhoneNumberMutationVariables): Promise<ResolveVerifyPhoneNumberResult> {
      const OPERATION_NAME = "VerifyPhoneNumber";
      const ERROR_MESSAGE =
        ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { verifyPhoneNumber },
        } = result;

        if (
          !verifyPhoneNumber ||
          verifyPhoneNumber.__typename === "Errors" ||
          !verifyPhoneNumber.phoneVerified
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(verifyPhoneNumber);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveVerify2FA({
      input,
    }: Verify2FaMutationVariables): Promise<ResolveVerify2FAResult> {
      const OPERATION_NAME = "Verify2FA";
      const ERROR_MESSAGE =
        ErrorMessages.twoFactorAuth.GENERIC_VERIFICATION_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { verify2FA },
        } = result;

        if (
          !verify2FA ||
          verify2FA.__typename === "Errors" ||
          !verify2FA.success
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddPhoneNumber({
      input,
    }: AddPhoneNumberMutationVariables): Promise<ResolveAddPhoneNumberResult> {
      const OPERATION_NAME = "AddPhoneNumber";
      const ERROR_MESSAGE = ErrorMessages.phoneEntry.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addPhoneNumber },
        } = result;

        if (
          !addPhoneNumber ||
          addPhoneNumber.__typename === "Errors" ||
          !addPhoneNumber.phone
        ) {
          return err(ERROR_MESSAGE);
        }

        return ok(addPhoneNumber);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveSetTheme({
      input,
    }: SetThemeMutationVariables): Promise<Result<true, string>> {
      const OPERATION_NAME = "SetTheme";
      const ERROR_MESSAGE = ErrorMessages.summary.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });

        const {
          data: { setTheme },
        } = result;

        if (!setTheme || setTheme.__typename === "Errors" || !setTheme.bool) {
          return err(ERROR_MESSAGE);
        }

        return ok(true);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveAddTaxpayerId({
      input,
    }: AddTaxpayerIdMutationVariables): Promise<ResolveAddTaxpayerIdResult> {
      const OPERATION_NAME = "AddTaxpayerId";
      const ERROR_MESSAGE = ErrorMessages.taxpayerId.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { addTaxpayerIdFull },
        } = result;

        if (
          !result ||
          !addTaxpayerIdFull ||
          addTaxpayerIdFull?.__typename === "Errors" ||
          !addTaxpayerIdFull?.taxpayerId
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(addTaxpayerIdFull);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveRiskSession(): Promise<ResolveRiskSessionResult> {
      const OPERATION_NAME = "RiskSession";
      const ERROR_MESSAGE = "Unable to initialize the Meso transfer flow.";

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { riskSession },
        } = result;

        if (!result || !riskSession || riskSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(riskSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveNewSession({
      input,
    }: ResolveNewSessionMutationVariables): Promise<
      Result<
        Extract<
          ResolveNewSessionMutation["newSession"],
          { __typename: "Session" }
        >,
        string
      >
    > {
      const OPERATION_NAME = "ResolveNewSession";
      const ERROR_MESSAGE = ErrorMessages.newSession.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ input });
        const {
          data: { newSession },
        } = result;

        if (!result || !newSession || newSession.__typename === "Errors") {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(newSession);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveUserActivationStatus({
      id,
    }: UserActivationStatusQueryVariables): Promise<ResolveUserActivationStatusResult> {
      const OPERATION_NAME = "UserActivationStatus";
      const ERROR_MESSAGE = ErrorMessages.userActivation.GENERIC_API_ERROR;

      try {
        const result = await sdk[OPERATION_NAME]({ id });
        const {
          data: { userActivation },
        } = result;

        if (
          !result ||
          !userActivation ||
          userActivation?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });
          return err(ERROR_MESSAGE);
        }

        return ok(userActivation);
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);
      }

      return err(ERROR_MESSAGE);
    },

    async resolveIdentityDocumentUrl(): Promise<ResolveIdentityDocumentUrlResult> {
      const OPERATION_NAME = "IdentityDocumentUrl";
      const ERROR_MESSAGE =
        ErrorMessages.identityDocument.UNABLE_TO_RETRIEVE_DOCUMENT_UPLOAD_URL;

      try {
        const result = await sdk[OPERATION_NAME]();
        const {
          data: { identityDocumentUrl },
        } = result;

        if (
          !result ||
          !identityDocumentUrl ||
          identityDocumentUrl?.__typename === "Errors"
        ) {
          Sentry.captureMessage(ERROR_MESSAGE, {
            level: "warning",
            tags: { operation: OPERATION_NAME },
            extra: {
              result: JSON.stringify(result),
              requestId: result.headers.get("x-meso-request") ?? "unknown",
            },
          });

          return err(ERROR_MESSAGE);
        }

        return ok({
          ...identityDocumentUrl,
          url: identityDocumentUrl.url as UrlString,
        });
      } catch (error: unknown) {
        reportApiError(error, OPERATION_NAME);

        return err(ERROR_MESSAGE);
      }
    },
  };
};
