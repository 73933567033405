import * as sentry from "@sentry/react";

/**
 * Shared sentry configurations used in the transfer-app, account app, and onboarding app.
 */
export const commonSentryConfig: Partial<sentry.BrowserOptions> = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.CI !== "true",
  environment: import.meta.env.VITE_TIGRIS_ENV,
  release: import.meta.env.VITE_TIGRIS_RELEASE,
  debug: import.meta.env.VITE_SENTRY_DEBUG === "1",
  tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  replaysSessionSampleRate: Number(
    import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  ),
  replaysOnErrorSampleRate: Number(
    import.meta.env.VITE_SENTRY_ERROR_REPLAY_SESSION_SAMPLE_RATE,
  ),

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
  tracePropagationTargets: [
    "localhost",
    "127.0.0.1",
    /^https:\/\/api\.dev\.meso\.plumbing/,
    /^https:\/\/api\..*\.meso\.network/,
    /^https:\/\/transfer\.dev\.meso\.plumbing/,
    /^https:\/\/transfer\..*\.meso\.network/,
  ],
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],

  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    "Network request failed",
    "AbortError: Aborted",
    // When passkey negotiation takes too long, this error is surfaced, but we handle it gracefully and the user can retry
    // Example issue: https://meso-inc.sentry.io/issues/5428938881/?alert_rule_id=14598235&alert_type=issue&notification_uuid=ffbcab25-fa6b-44fa-8e27-8274467349c5&project=4505665559986176&referrer=slack
    "The operation either timed out or was not allowed.",
    "The operation is insecure.",
    "An unknown error occurred while talking to the credential manager.",
    "NotReadableError: An unknown error occurred while talking to the credential manager.",
    "Load failed",
    "The user agent does not support public key credentials.",
    "Failed to find next route",
    // https://stackoverflow.com/a/73862845
    // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#notreadableerror
    "NotReadableError: The I/O read operation failed.",
    // https://sentry.io/answers/failed-to-fetch-javascript/
    "Failed to fetch",
    "null is not an object (evaluating 'this._logicalFormForControl(e).formElement')",
    "Invalid call to runtime.sendMessage(). Tab not found.",
  ],
};
