import { OnboardingContextValue, UrlString, UserStatus } from "../types";
import { Button, Title, Text, LoadingInterstitial } from "@tigris/mesokit";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { motionVariants } from "../utils/animation";
import { useOnboarding } from "../hooks/useOnboarding";
import { useApi } from "../hooks/useApi";
import { useRouter } from "../hooks/useRouter";
import { DocumentVerification } from "./DocumentVerification";

const UnderReview = ({
  onReturnToTransfer,
}: {
  onReturnToTransfer: OnboardingContextValue["returnToTransfer"];
}) => (
  <motion.form
    {...motionVariants.fadeIn}
    name="ManualReview"
    id="ManualReview"
    data-testid="ManualReview"
    onSubmit={(event: FormEvent) => {
      event.preventDefault();
      onReturnToTransfer("onboardingTerminated");
    }}
    className="flex h-full flex-col gap-2 pt-8"
  >
    <Title.Medium bold>Account Under Review</Title.Medium>
    <Text>
      Your account is currently under review. We will contact you shortly with
      the next steps. If you have any questions please visit our{" "}
      <a
        href="https://support.meso.network/"
        target="_blank"
        className="underline transition-opacity hover:opacity-90"
        rel="noreferrer"
      >
        support center
      </a>
      .
    </Text>

    <div className="mt-auto">
      <Button
        key="ManualReview:button"
        type="submit"
        data-testid="ManualReview:closeButton"
      >
        Close
      </Button>
    </div>
  </motion.form>
);

export const ManualReview = () => {
  const {
    api: { resolveIdentityDocumentUrl },
  } = useApi();
  const { user, returnToTransfer } = useOnboarding();
  const { disableNavigation, currentRoute } = useRouter();
  const isManualReview = useMemo(() => {
    if (user.activation?.userStatus === UserStatus.IN_REVIEW) {
      return true;
    }

    if (
      currentRoute.state &&
      "forceManualReview" in currentRoute.state &&
      currentRoute.state.forceManualReview
    ) {
      return true;
    }

    return false;
  }, [currentRoute.state, user.activation?.userStatus]);

  useEffect(() => disableNavigation(), [disableNavigation]);

  const [isLoading, setIsLoading] = useState(isManualReview);
  const [identityDocumentUrl, setIdentityDocumentUrl] =
    useState<UrlString | null>(null);
  const [hasIdentityDocumentUrlError, setHasIdentityDocumentUrlError] =
    useState(false);

  useEffect(() => {
    (async () => {
      if (isManualReview) {
        const identityDocumentUrlResult = await resolveIdentityDocumentUrl();

        if (identityDocumentUrlResult.isErr()) {
          setHasIdentityDocumentUrlError(true);
        } else {
          setIdentityDocumentUrl(
            identityDocumentUrlResult.value.url as UrlString,
          );
        }

        // UI buffer to allow the loading transition to occur
        setTimeout(() => setIsLoading(false), 200);
      }
    })();
  }, [isManualReview, resolveIdentityDocumentUrl]);

  const renderDocumentVerification = useMemo(() => {
    return (
      !hasIdentityDocumentUrlError && isManualReview && identityDocumentUrl
    );
  }, [hasIdentityDocumentUrlError, identityDocumentUrl, isManualReview]);

  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <LoadingInterstitial mode="waiting" />
      ) : renderDocumentVerification && identityDocumentUrl ? (
        <DocumentVerification
          identityDocumentUrl={identityDocumentUrl}
          onReturnToTransfer={returnToTransfer}
        />
      ) : (
        <UnderReview onReturnToTransfer={returnToTransfer} />
      )}
    </AnimatePresence>
  );
};
